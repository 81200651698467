import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/utils/axios';
import { getDateTimeToString } from 'src/utils/date';
import { SENSOR_TYPES } from 'src/constants';
import {
  Droplet as WaterIcon,
  Zap as ElectricityIcon,
  Cloud as AirhumidityIcon,
  Sun as HeatenergyIcon,
  Truck as EVchargingIcon
} from 'react-feather';
import { makeStyles } from '@mui/styles';
import { formatDateTime } from 'src/utils/formatDateTime';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  },
  table: {
    border: 'none'
  },
  tablecell: {
    border: 'none',
    padding: '0px'
  }


}));


function LatestReceivedMeasurementValues({ className, ...rest }) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [latestValues, setLatestValues] = useState(null);

  const getLatestReceivedMeasurementValues = useCallback(async () => {
    setLatestValues([]);

    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/metering/GetLastReceivedValues`)
      .then((response) => {
        if (isMountedRef.current) {
          //  console.log(response.data); 
          setLatestValues(response.data);
        }
      });
  }, [isMountedRef]);


  useEffect(() => {
    let intervalId = 0;
    getLatestReceivedMeasurementValues();
    intervalId = setInterval(() => getLatestReceivedMeasurementValues(), 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getLatestReceivedMeasurementValues]);

  function getSensorIcon(sensortype, index) {
    switch (sensortype) {
      case SENSOR_TYPES.COLDWATER:
        return [<WaterIcon key={index} fontSize="small" color="blue" />];
      case SENSOR_TYPES.HOTWATER:
        return [<WaterIcon key={index} fontSize="small" color="red" />];
      case SENSOR_TYPES.ELECTRICITY:
        return [<ElectricityIcon key={index} fontSize="small" color="yellow" />];
      case SENSOR_TYPES.AIRHUMIDITY:
        return [<AirhumidityIcon key={index} fontSize="small" />];
      case SENSOR_TYPES.HEATENERGY:
        return [<HeatenergyIcon key={index} fontSize="small" color="red" />];
      case SENSOR_TYPES.EVCHARGING:
        return [<EVchargingIcon key={index} fontSize="small" />];
      default:
        return '';
    }
  }

  if (!latestValues) {
    return null;
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box flexGrow={1}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          Senast inkomna mätvärden
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >

          <TableContainer>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tablecell}>
                    Tidpunkt
                  </TableCell>
                  <TableCell className={classes.tablecell}>
                    Fastighetsägare
                  </TableCell>
                  <TableCell className={classes.tablecell}>
                    Fastighet
                  </TableCell>
                  <TableCell className={classes.tablecell}>
                    Typ
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {latestValues.map((row) => (
                  <TableRow key={row.timestamp} value={row}>
                    <TableCell className={classes.tablecell}>
                      {formatDateTime(row.timestamp)}
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      {row.propertyOwnerName}
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      {row.propertyName}
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      {getSensorIcon(row.sensortype, row.timestamp)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>


        </Box>


      </Box>
    </Card>
  );
}

LatestReceivedMeasurementValues.propTypes = {
  className: PropTypes.string
};

export default LatestReceivedMeasurementValues;
