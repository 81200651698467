import { CardActionArea, CardActions, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Card, CardContent, Divider, Button } from '@mui/material';
import React from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DefaultButton from 'src/components/DefaultButton';
// import { Table } from 'react-virtualized';
import NavigationButton from 'src/components/NavigationButton';
import { APPLICATION } from 'src/constants';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import API from 'src/utils/api';
import { ApplicationStatusLabel } from '../../components/ApplicationStatusLabel';
// import Typography from 'src/theme/typography';
import DashboardCard from './DashboardCard';
import DashboardCardTitle from './DashboardCardTitle';
import { makeStyles } from '@mui/styles';
import axios from 'src/utils/axios';
import { convertJsonToHtml } from 'src/utils/convertJsonToHtml';

const useStyles = makeStyles((theme) => ({
card: {
    marginBottom: theme.spacing(2),
},
title: {
    fontWeight: 'bold',
    fontSize: '19px',
    marginBottom: theme.spacing(1),
},
date: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
},
description: {
    fontSize: '1rem',
},
button: {
    marginTop: theme.spacing(1),
},
}));

export default function NewFeatures() {
    const classes = useStyles();
    const [contents, setContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
  
    const fetchContents = async () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            const response = await axios.get(`${baseUrl}/api/news/fordashboard`);
            //console.log('Fetched content:', response.data); // Logga API-responsen
            const parsedContents = response.data
            .filter((item) => item.isActive && item.showOnDash)
            .map((item) => {
            try {
                // Försök att konvertera JSON till ProseMirror innan vi kör konverteringen
                const parsedContent = JSON.parse(item.content); // Om innehållet är en sträng
                //console.log('Parsed content:', parsedContent);
                return {
                ...item,
                content: convertJsonToHtml(parsedContent), // Parsar varje content-sträng
                };
            } catch (error) {
                //console.error('Error parsing content JSON:', error);
                return item; // Om något går fel, lämna item utan konvertering
            }
            });
            setContents(parsedContents);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching content:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
    fetchContents();
    }, []);

    if (!isLoading && contents.length === 0) {
    return null;
    }

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // månad 0-indexerad
        const day = String(date.getDate()).padStart(2, '0');
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${year}-${month}-${day}`; // Format: yyyy-mm-dd HH:MM
    };

    return (
    <DashboardCard>
        <DashboardCardTitle title="Nyheter i BKEye" />
        <Card className={classes.card}>
            <CardContent>
            {/*<Typography variant="h6" className={classes.title}>
                Senaste Nyheterna
            </Typography>
            <Divider style={{ marginBottom: "20px"}}/>*/}
            
            {/* Nyhet 1 */}
            {isLoading ? (
                <></>
            ) : (
            contents.map((contentItem, index) => (
            <>
            <Typography variant="body1" className={classes.date}>
                {contentItem.showDate && contentItem.showDate !== "0001-01-01T00:00:00" ? formatDateTime(contentItem.showDate) : contentItem.createdAt && contentItem.createdAt !== "0001-01-01T00:00:00" ? formatDateTime(contentItem.createdAt) : ""}
            </Typography>
            <Typography variant="h6" className={classes.title}>
                {contentItem.title}
            </Typography>
            <Typography
                variant="body2"
                className={classes.description}
                component="div"
                dangerouslySetInnerHTML={{ __html: contentItem.content }}
            />
            {contentItem.link &&
            <Button variant="outlined" color="primary" href={contentItem.link} className={classes.button}>
                Läs mer
            </Button>
            }
            
            {index !== contents.length - 1 && (
                <Divider style={{ margin: '20px 0' }} />
            )}
            </>
            )))}
            </CardContent>
        </Card>
        
        <NavigationButton to="/app/news" text="Visa fler" className={classes.button} />
    </DashboardCard>
    )
}