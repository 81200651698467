import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';

export default function AlertDialog({
  open = false,
  handleClose = () => {},
  handleConfirm = () => {},
  text = 'Vill du verkligen göra det här?',
  cancelText = 'Nej',
  confirmText = 'Ja'
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelText}
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AlertDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  text: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string
};

/*AlertDialog.defaultProps = {
  open: false,
  handleClose: () => {},
  handleConfirm: () => {},
  text: 'Vill du verkligen göra det här?',
  cancelText: 'Nej',
  confirmText: 'Ja'
};
*/