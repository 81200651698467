import React from 'react';
import {
  Container,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import Header from '../Header';
import SystemStatistics from '../SystemStatistics';
import LatestReceivedMeasurementValues from '../LatestReceivedMeasurementValues';
import MasterMap from 'src/components/map/MasterMap';
import SpotPricesForecast from '../SpotPricesForecast';
import ApplicationStatusSummary from '../ApplicationStatusSummary';
import SpotPrices from '../SpotPrices';
import NewFeatures from '../NewFeatures';
import DynamicNews from '../DynamicNews';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 48,
      paddingRight: 48
    }
  }
}));


function SuperAdminDashboardView({
  timeRange, timeRanges, setTimeRange, className, ...rest
}) {
  const classes = useStyles();

  const GridItem = ({ children }) => {
    return <Grid
      item
      lg={6}
      sm={6}
      xs={12}
    >
      {children}
    </Grid>
  };

  return (
    <Container
      maxWidth={false}
      className={classes.container}
    >
      <Header timeRange={timeRange} timeRanges={timeRanges} setTimeRange={setTimeRange} />
      <Grid
        container
        spacing={3}
      >
        <GridItem>
          <SystemStatistics timeRange={timeRange} timeRanges={timeRanges} />
        </GridItem>
        <GridItem>
          <ApplicationStatusSummary />
        </GridItem>
        <GridItem>
          <MasterMap />
        </GridItem>
        <GridItem>
          <LatestReceivedMeasurementValues />
        </GridItem>
        <GridItem>
          <SpotPricesForecast />
        </GridItem>
        <GridItem>
          <SpotPrices />
        </GridItem>
        {DynamicNews() !== null && (
        <GridItem>
          <DynamicNews/>
        </GridItem>
        )}
        {NewFeatures() !== null && (
        <GridItem>
          <NewFeatures/>
        </GridItem>
        )}

      </Grid>
    </Container>
  );
}
export default SuperAdminDashboardView;
