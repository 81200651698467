import { DOMSerializer, Node, Schema } from "prosemirror-model";
import { schema } from 'prosemirror-schema-basic';
//import { table, tableRow, tableCell} from 'prosemirror-schema-table';
//console.log(schema.spec.nodes);

const table = {
group: "block",
content: "table_row+",
parseDOM: [{ tag: "table" }],
toDOM() {
    return ["table", { style: "width: 100% !important; border: 1px solid #666666 !important; border-spacing: 0px !important;" }, 0];
}
};

const tableRow = {
group: "block",
content: "table_cell+",
parseDOM: [{ tag: "tr" }],
toDOM() {
    return ["tr", 0];
}
};

const tableCell = {
content: "inline*",
attrs: {
    colspan: { default: 1 },
    rowspan: { default: 1 },
    style: { default: null },
},
parseDOM: [{ tag: "td" }],
toDOM(node) {
    const { colspan, rowspan, style } = node.attrs;
    return ["td", { colspan, rowspan, style: "border: 1px solid #666666; border-collapse: collapse;" }, 0];
}
};

const bulletList = {
group: "block",
content: "list_item+",
parseDOM: [{ tag: "ul" }],
toDOM() {
    return ["ul", { style: "margin-left: 15px;" }, 0];
}
};

const orderedList = {
group: "block",
content: "list_item+",
attrs: {
    order: { default: 1 }
},
parseDOM: [{ tag: "ol" }],
toDOM(node) {
    return ["ol", { start: node.attrs.order, style: "margin-left: 15px;" }, 0];
}
};

const listItem = {
content: "inline*",
parseDOM: [{ tag: "li" }],
toDOM() {
    return ["li", 0];
}
};

const styleMark = {
parseDOM: [
    {
    tag: "span[style]",
    getAttrs(dom) {
        return { style: dom.style.cssText };  // Hämta CSS-stilen som en sträng
    }
    }
],
toDOM(node) {
    return ["span", { style: node.attrs.style }, 0];  // Lägg till style-atributet
}
};

const divNode = {
content: "block+",  // En div kan innehålla block-element
group: "block",     // Den här noden är en block-nod
parseDOM: [
    {
    tag: "div", // Här söker vi efter <div> elementet i DOM
    getAttrs(dom) {
        return {
        style: dom.style.cssText,
        class: dom.className,
        id: dom.id
        };
    }
    }
],
toDOM(node) {
    return [
    "div", 
    { style: node.attrs.style, class: node.attrs.class, id: node.attrs.id },
    0 // Innehållet i div:n
    ];
}
};

const underlineMark = {
parseDOM: [
    { tag: "u" }  // Här söker vi efter <u> taggen i DOM
],
toDOM() {
    return ["u", 0];  // Detta kommer att generera en <u> tagg i DOM
}
};

const paragraph = {
    content: "inline*",
    attrs: {
        style: { default: "" },  // Ge en default tom sträng för style
        class: { default: null },
        id: { default: null }
    },
    parseDOM: [{ tag: "p" }],
    toDOM(node) {
       // console.log(node);
        const { style } = node.attrs;  // Hämta stilattributet
        const styleAttr = style ? { style } : {};  // Lägg till style om det finns
        if (!node.content.size) {
            return ["p", styleAttr, ["br"]]; // Lägg till en <br> om noden är tom
        }
        return ["p", styleAttr, 0];  // Lägg till style-atributet i DOM
    }
};

const heading = {
    content: "inline*",  // Innehåller text eller andra inline-element
    attrs: {
        style: { default: "" },
        class: { default: null },
        id: { default: null },
        level: { default: 1 }  // Default är level 1, som motsvarar <h1>
    },
    parseDOM: [
        {
            tag: "h1",
            getAttrs(dom) {
                return { level: 1, style: dom.style.cssText, class: dom.className, id: dom.id };
            }
        },
        {
            tag: "h2",
            getAttrs(dom) {
                return { level: 2, style: dom.style.cssText, class: dom.className, id: dom.id };
            }
        },
        {
            tag: "h3",
            getAttrs(dom) {
                return { level: 3, style: dom.style.cssText, class: dom.className, id: dom.id };
            }
        },
        {
            tag: "h4",
            getAttrs(dom) {
                return { level: 4, style: dom.style.cssText, class: dom.className, id: dom.id };
            }
        },
        {
            tag: "h5",
            getAttrs(dom) {
                return { level: 5, style: dom.style.cssText, class: dom.className, id: dom.id };
            }
        },
        {
            tag: "h6",
            getAttrs(dom) {
                return { level: 6, style: dom.style.cssText, class: dom.className, id: dom.id };
            }
        }
    ],
    toDOM(node) {
        const { level, style, class: className, id } = node.attrs;
        // Välj rätt h-tag beroende på level
        const tag = `h${level}`;
        const styleAttr = style ? { style } : {};  // Lägg till style om det finns
        const classAttr = className ? { class: className } : {};
        const idAttr = id ? { id } : {};

        // Återvänd en array med taggen och attributen
        return [tag, { ...styleAttr, ...classAttr, ...idAttr }, 0];
    }
};


function applyStyle(attrs) {
   // console.log(attrs);
    if (attrs.style) {
        return { style: attrs.style };
    }
    return {};
}

const imageNode = {
    inline: true,
    attrs: {
      src: {},
      alt: { default: null },
      title: { default: null },
      width: { default: 200 },  // Standardvärde för bredd
      height: { default: 100 }, // Standardvärde för höjd
      style: { default: null },
      class: { default: null },
      id: { default: null }
    },
    parseDOM: [
      {
        tag: "img",
        getAttrs(dom) {
          return {
            src: dom.getAttribute("src"),
            alt: dom.getAttribute("alt"),
            title: dom.getAttribute("title"),
            width: dom.getAttribute("width"),
            height: dom.getAttribute("height"),
            style: dom.style.cssText,
            class: dom.className,
            id: dom.id
          };
        }
      }
    ],
    toDOM(node) {
      const { src, alt, title, width, height, style, class: className, id } = node.attrs;
      const updatedStyle = `${style || ""} max-width: 100%; height: auto;`;
      return [
        "img",
        {
          src,
          alt,
          title,
          width,
          height,
          style: updatedStyle,
          class: className,
          id
        }
    ];
  }
};
  

// Lägg till tabellnoderna i det utökade schemat
const extendedSchema = new Schema({
nodes: schema.spec.nodes.append({
    heading,
    paragraph,
    table,
    table_row: tableRow,
    table_cell: tableCell,  // Lägg till table_cell här
    bullet_list: bulletList,  // Lägg till bullet_list
    ordered_list: orderedList,  // Lägg till ordered_list
    list_item: listItem,
    div: divNode,
    image: imageNode
}),
marks: schema.spec.marks.addBefore("strong", "u", underlineMark)
.addBefore("u", "style", styleMark)
});

// Funktion för att omvandla JSON till HTML
export const convertJsonToHtml = (content) => {
// Konvertera JSON till en ProseMirror-nod
const contentNode = Node.fromJSON(extendedSchema, content);

// Skapa en "dummy" DOM-struktur för att använda DOMSerializer
const contentDiv = document.createElement("div");

// Använd DOMSerializer för att serialisera innehållet och sätt in det i contentDiv
DOMSerializer.fromSchema(extendedSchema).serializeFragment(
    contentNode.content,
    { document }, // Passera det globala dokumentobjektet
    contentDiv
);

// Returnera HTML-strängen från div-elementet
//console.log(contentDiv.innerHTML);
return contentDiv.innerHTML;
};