import { CardActionArea, CardActions, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Card, CardContent, Divider, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, DialogContentText, Box } from '@mui/material';
import React from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DefaultButton from 'src/components/DefaultButton';
// import { Table } from 'react-virtualized';
import NavigationButton from 'src/components/NavigationButton';
import { APPLICATION } from 'src/constants';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import API from 'src/utils/api';
import { ApplicationStatusLabel } from '../../components/ApplicationStatusLabel';
// import Typography from 'src/theme/typography';
import DashboardCard from './DashboardCard';
import DashboardCardTitle from './DashboardCardTitle';
import { makeStyles } from '@mui/styles';
import { convertJsonToHtml } from 'src/utils/convertJsonToHtml';
import axios from 'src/utils/axios';
import { enqueueSnackbar } from 'notistack';
import AlertDialog from 'src/components/AlertDialog';
import CircleExclamationIcon from 'src/components/icons/CircleExclamationIcon';

const useStyles = makeStyles((theme) => ({
card: {
    marginBottom: theme.spacing(2),
},
title: {
    fontWeight: 'bold',
    fontSize: '19px',
},
date: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
    marginBottom: theme.spacing(1),
},
description: {
    fontSize: '1rem',
},
button: {
    marginTop: theme.spacing(1),
},
objectCountIcon: {
    marginLeft: "-10px",
    marginRight: "15px"
},
}));

export default function DynamicNews() {
    const classes = useStyles();
    const [contents, setContents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [selectedMessage, setSelectedMessage] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        mail: ''
    });
    const [originalData, setOriginalData] = useState({});
    const [userId, setUserId] = useState(null);

    const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

    const fetchUserData = async () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            const response = await axios.get(`${baseUrl}/api/account/me`);
            const userData = response.data.user;
            setFormData({
                name: userData.name || '',
                phone: userData.phone || '',
                mail: userData.email || ''
            });
            setOriginalData({
                phone: userData.phone || '',
                cellphone: userData.cellphone || '',
                mail: userData.email || '',
                city: userData.city || '',
                address: userData.address || '',
                company: userData.company || '',
                zipcode: userData.zipcode || '',
                lastName: userData.lastName || '',
                firstName: userData.firstName || ''
            });
            setUserId(userData.id); // Spara användarens ID
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };    

    const updateProfile = async (updatedData) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
    
        try {
            const response = await axios.post(`${baseUrl}/api/account/profile`, {
                update: {
                    id: userId,
                    ...updatedData,
                    cellphone: originalData.cellphone || '',
                    city: originalData.city || '',  // Säkerställ att fält finns
                    address: originalData.address || '',
                    company: originalData.company || '',
                    zipcode: originalData.zipcode || '',
                    lastName: originalData.lastName || '',
                    firstName: originalData.firstName || ''
                }
            });
            console.log('Profile updated successfully:', response);
            enqueueSnackbar("Profil uppdaterad framgångsrikt", { variant: 'success' });
        } catch (error) {
            enqueueSnackbar("Kunde inte uppdatera profilen", { variant: 'error' });
            console.error('Error updating profile:', error);
        }
    };    
  
    const fetchContents = async () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        try {
            const response = await axios.get(`${baseUrl}/api/upload/fordashboard`);
            //console.log('Fetched content:', response.data); // Logga API-responsen
            const parsedContents = response.data
            .filter((item) => item.isActive && item.showOnDash)
            .map((item) => {
            try {
                // Försök att konvertera JSON till ProseMirror innan vi kör konverteringen
                const parsedContent = JSON.parse(item.content); // Om innehållet är en sträng
                //console.log('Parsed content:', parsedContent);
                return {
                ...item,
                content: convertJsonToHtml(parsedContent), // Parsar varje content-sträng
                };
            } catch (error) {
                //console.error('Error parsing content JSON:', error);
                return item; // Om något går fel, lämna item utan konvertering
            }
            });
            setContents(parsedContents);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching content:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
    fetchContents();
    fetchUserData();
    }, []);

    if (!isLoading && contents.length === 0) {
    return null;
    }

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // månad 0-indexerad
        const day = String(date.getDate()).padStart(2, '0');
    
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
    
        return `${year}-${month}-${day}`; // Format: yyyy-mm-dd HH:MM
    };

    const handleDialogOpen = (title, message) => {
        setSelectedTitle(title);
        setSelectedMessage(message ? message : "");
        setOpenDialog(true);
    };

    const handleDialogClose = () => {
    setOpenDialog(false);
    };

    const handleInputChange = (e) => {
    setFormData({
        ...formData,
        [e.target.name]: e.target.value,
    });
    };

    const handleConfirmDialogOpen = () => {
        setIsConfirmDialogOpen(true); // Öppna dialogen för bekräftelse
    };

    const handleConfirmDialogClose = () => {
        setIsConfirmDialogOpen(false); // Stäng dialogen om användaren väljer avbryt
    };

    const handleConfirm = async () => {
        // Om användaren bekräftar, uppdatera profilen
        await updateProfile({
            phone: formData.phone,
            email: formData.mail
        });
        setIsConfirmDialogOpen(false); // Stäng dialogen efter uppdatering
    };

    const handleFormSubmit = async () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
    
        const updatedFormData = {
            ...formData,
            subject: selectedTitle,
            message: selectedMessage,
        };
    
        // Kontrollera om telefon eller mail har ändrats
        if (formData.phone !== originalData.phone || formData.mail !== originalData.mail) {
            handleConfirmDialogOpen();
        }
    
        try {
            const response = await axios.post(`${baseUrl}/api/uploadcontactform`, updatedFormData);
            console.log('Form submitted successfully:', response);
            enqueueSnackbar("Formulär skickat framgångsrikt", { variant: 'success' });
            setOpenDialog(false);
        } catch (error) {
            enqueueSnackbar("Något gick fel", { variant: 'error' });
            console.error('Error submitting form:', error);
        }
    };        

    return (
    <>
    <DashboardCard>
        <DashboardCardTitle title="BKAB Tipsar" />
        {isLoading ? (
                <></>
            ) : (
            contents.map((contentItem, index) => (
                <Card key={index} className={classes.card}>
                    <CardContent>
                        <Typography variant="body1" className={classes.date}>
                        {contentItem.showDate && contentItem.showDate !== "0001-01-01T00:00:00" ? formatDateTime(contentItem.showDate) : contentItem.createdAt && contentItem.createdAt !== "0001-01-01T00:00:00" ? formatDateTime(contentItem.createdAt) : ""}
                        </Typography>
                        {contentItem.title != "" &&
                        <Typography
                            variant="h6"
                            className={classes.title}
                            gutterBottom
                            sx={{mb: 1}}
                        >
                            {contentItem.title || ""}
                        </Typography>
                        }
                        <Typography
                            variant="body2"
                            className={classes.description}
                            component="div"
                            dangerouslySetInnerHTML={{ __html: contentItem.content }}
                        />
                        {contentItem.mailSubject && contentItem.mailSubject != "0" &&
                        <Button variant="contained" color="secondary" className={classes.button} onClick={() => handleDialogOpen(contentItem.mailSubject, contentItem.mailMessage)}>
                        {contentItem.buttonText ? contentItem.buttonText : "Bli kontaktad"}
                        </Button>
                        }
                        {contentItem.buttonLink &&
                        <Button variant="contained" color="secondary" className={classes.button} href={contentItem.buttonLink} target={contentItem.buttonNewWindow ? "_blank" : ""}>
                        {contentItem.buttonText ? contentItem.buttonText : "Visa mer"}
                        </Button>
                        }
                    </CardContent>
                </Card>
            ))
        )}
        <NavigationButton to="/app/advices" text="Visa fler" className={classes.button} />
    </DashboardCard>

    <Dialog open={openDialog} onClose={handleDialogClose}>
    <DialogTitle variant="h6" className={classes.title}>{selectedTitle}</DialogTitle>
    <DialogContent>
    <TextField
        label="Namn *"
        fullWidth
        margin="normal"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        disabled
    />
    <TextField
        label="Telefonnummer *"
        fullWidth
        margin="normal"
        name="phone"
        value={formData.phone}
        onChange={handleInputChange}
    />
    <TextField
        label="E-post *"
        fullWidth
        margin="normal"
        name="mail"
        value={formData.mail}
        onChange={handleInputChange}
    />
    <TextField
        label="Meddelande"
        fullWidth
        margin="normal"
        value={selectedMessage}
        onChange={(e) => setSelectedMessage(e.target.value)}
        multiline
        rows={2}
    />
    </DialogContent>
    <DialogActions>
    <Button onClick={handleDialogClose} color="secondary">
        Avbryt
    </Button>
    <Button onClick={handleFormSubmit} color="primary" disabled={!formData.name || !formData.phone || !formData.mail}>
        Skicka
    </Button>
    </DialogActions>
    </Dialog>

    <div>
      <Dialog
        open={isConfirmDialogOpen}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h5">Vill du ändra dina uppgifter?</Typography>
          <Box sx={{ display: "inline-flex", alignItems: "center", marginTop: 1 }}>
          <CircleExclamationIcon size="3x" className={classes.objectCountIcon} color="#3949AB"/>
          <DialogContentText id="alert-dialog-description">
          Några av dina angivna uppgifter stämmer inte överens med uppgifterna i din profil. Vill du uppdatera din profil med de nya uppgifterna?
          </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDialogClose} color="primary">
            Nej
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    {/*<AlertDialog
        open={isConfirmDialogOpen}
        handleClose={handleConfirmDialogClose}
        handleConfirm={handleConfirm}
        text="Några av dina uppgifter stämmer inte överens med dina gamla uppgifter, vill du uppdatera din profil med de nya uppgifterna?"
        cancelText="Nej"
        confirmText="Ja"
    />*/}
    </>
    )
}