import React from 'react';
import {
  Container,
  Grid
} from '@mui/material';
import Header from '../Header';
import SystemStatistics from '../SystemStatistics';
import MasterMap from 'src/components/map/MasterMap';
import SpotPricesForecast from '../SpotPricesForecast';
import SpotPrices from '../SpotPrices';
import { makeStyles } from '@mui/styles';
import NewFeatures from '../NewFeatures';
import DynamicNews from '../DynamicNews';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 48,
      paddingRight: 48
    }
  }
}));

function PropertyOwnerAdminDashboardView({ timeRange, timeRanges, setTimeRange, className, ...rest }) {
  const classes = useStyles();

  return (
    <Container
      maxWidth={false}
      className={classes.container}
    >
      <Header timeRange={timeRange} timeRanges={timeRanges} setTimeRange={setTimeRange} />
      <Grid
        container
        spacing={3}
      >
        {/* <Grid
          item
          lg={3}
          sm={6}
          xs={12}
        >
          <SystemStatistics timeRange={timeRange} timeRanges={timeRanges} />
        </Grid> */}
        <Grid
          container
          item
          lg={6}
          sm={6}
          xs={12}
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <SystemStatistics timeRange={timeRange} timeRanges={timeRanges} />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <MasterMap />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <SpotPricesForecast />
          </Grid>
          <Grid
            item
            xs={12}
          >
            <SpotPrices />
          </Grid>
        </Grid>
        <Grid
          item
          lg={6}
          sm={6}
          xs={12}
        >
          <Grid
            container
            direction="column"
            spacing={3}
          >
            {DynamicNews() !== null && (
            <Grid item xs={12}>
              <DynamicNews />
            </Grid>
            )}
            {NewFeatures() !== null && (
            <Grid item xs={12}>
              <NewFeatures />
            </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>


    </Container>
  );

}
export default PropertyOwnerAdminDashboardView;